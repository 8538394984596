// @ts-nocheck
// import MessageApi from '@/misc/apis/MessageApi'
import { user } from '@/mixins/user'
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex'
import ContactApi from '@/misc/apis/ContactApi'
import Vue from 'vue'
import { onboarding } from '@/mixins/onboarding'

// event transporter
export const eventBus = new Vue()

const mail = {
  mixins: [user, onboarding],
  data() {
    return {
      numberMessage: '-',
      numberDraftMessage: '-'
    }
  },
  computed: {
    ...mapState('message', ['readMails', 'unreadMails']),
    ...mapGetters('message', ['getMailboxesForUserId']),
    mailboxesForCurrentUser() {
      return this.getMailboxesForUserId(this.userId)
    }
  },
  mounted: function () {
    this.getNumberInterval = setInterval(() => this.countDraftMails(), 30000)
  },
  beforeDestroy() {
    clearInterval(this.getNumberInterval)
  },
  methods: {
    ...mapActions('message', ['loadEmailCounts', 'loadDraftEmailCounts']),
    ...mapMutations('message', [
      'setSendMailCC',
      'setSendMailContacts',
      'setSendMailPreselectedContact',
      'setSendMailReferenceIds',
      'setSendMailEmailToReply',
      'setSendMailEmailToForward',
      'setSendMailAttachmentMediaIds',
      'setBccReferenceIds',
      'setSendMailContactsWithObject',
      'setRenderWithObjectId',
      'setSelectedEntries',
      'setToAllTo'
    ]),
    ...mapMutations('onboarding', [
      'setTenancies',
      'setCondominiumOwnership'
    ]),
    ...mapMutations('onboarding', ['setContacts']),
    callShowSendMailModal(setToAllTo = false) {
      this.setToAllTo(setToAllTo)

      if (this.$store.getters['user/isSendMailModalOpen'] === false) {
        this.$bvModal.show('modal-send-mail')
      } else {
        this.$bvModal.show('draft-writing-email-modal')
      }
    },
    tableRowClass(item, type) {
      let classes = ''
      if (item && type === 'row') {
        // First check local read and unread store
        if (this.readMails.some(e => e.mailId === (item.id || item.objectID) && parseInt(e.accountId) === parseInt(this.accountId))) {
          classes = ''
        } else if (this.unreadMails.some(e => e.mailId === (item.id || item.objectID) && parseInt(e.accountId) === parseInt(this.accountId))) {
          classes += 'unread-mail'
          // Then check "backend" value
        } else if (!item.readEntries.some(e => parseInt(e.accountId) === parseInt(this.accountId))) {
          classes += 'unread-mail'
        }
      }
      return classes
    },
    countUnreadMails(force = false) {
      if (this.mailboxesForCurrentUser.length > 0) {
        const mailboxIds = this.mailboxesForCurrentUser.map(mailbox => mailbox.id)
        this.$nextTick(async () => {
          this.numberMessage = await this.loadEmailCounts({
            mailboxIds: mailboxIds,
            force: force
          })
        })
      } else {
        this.numberMessage = '0'
      }
    },
    async countDraftMails(force = false) {
      this.numberDraftMessage = await this.loadDraftEmailCounts(force)
    },
    getRecipientCount(mail) {
      return mail.to.length + mail.cc.length + mail.bcc.length
    },
    getDirection(mail) {
      if (!mail.from) {
        return ''
      }
      return this.mailboxesForCurrentUser.find(obj => obj.outboundEmail?.toLowerCase() === mail.from.email?.toLowerCase())
        ? 'from'
: this.mailboxesForCurrentUser.find(obj => mail.to.map(x => x.email?.toLowerCase()).includes(obj.outboundEmail?.toLowerCase()))
          ? 'to'
: this.mailboxesForCurrentUser.find(obj => mail.cc.length > 0 && mail.cc.map(x => x.email?.toLowerCase()).includes(obj.outboundEmail?.toLowerCase()))
            ? 'cc'
: this.mailboxesForCurrentUser.find(obj => mail.bcc.length > 0 && mail.bcc.map(x => x.email?.toLowerCase()).includes(obj.outboundEmail?.toLowerCase()))
              ? 'bcc'
: ''
    },
    getToContactReferences(mail, replyAll = false) {
      const direction = this.getDirection(mail)
      // We only want to reply one
      if (!replyAll) {
        // Our mailbox is the sender of the mail, so we have to use first TO or CC here
        if (direction === 'from') {
          if (mail.to && mail.to.length > 0) {
            const emailTo = mail.to[0].email
            let c = null
            if (this.contacts?.find(c => c.email === emailTo || c.workEmail === emailTo)) {
              c = this.contacts?.find(c => c.email === emailTo || c.workEmail === emailTo)
            } else if (this.contacts?.find(c => 'contactPersons' in c && c.contactPersons.some(cp => cp.email === emailTo || cp.workEmail === emailTo))) {
              const comp = this.contacts?.find(c => 'contactPersons' in c && c.contactPersons.some(cp => cp.email === emailTo || cp.workEmail === emailTo))
              c = comp.contactPersons.find(cp => cp.email === emailTo || cp.workEmail === emailTo)
            }
            return [{
              email: mail.to[0].email,
              id: c?.id,
              name: c?.name || mail.to[0].name
            }]
          } else if (mail.cc && mail.cc.length > 0) {
            const emailCC = mail.cc[0].email
            let c = null
            if (this.contacts?.find(c => c.email === emailCC || c.workEmail === emailCC)) {
              c = this.contacts?.find(c => c.email === emailCC || c.workEmail === emailCC)
            } else if (this.contacts?.find(c => 'contactPersons' in c && c.contactPersons.some(cp => cp.email === emailCC || cp.workEmail === emailCC))) {
              const comp = this.contacts?.find(c => 'contactPersons' in c && c.contactPersons.some(cp => cp.email === emailCC || cp.workEmail === emailCC))
              c = comp.contactPersons.find(cp => cp.email === emailCC || cp.workEmail === emailCC)
            }
            return [{
              email: mail.cc[0].email,
              id: c?.id,
              name: c?.name || mail.cc[0].name
            }]
          }
        } else {
          // Our mailbox is the recipient of the mail, so we have to reply to the sender of the mail
          // const c = this.contacts?.find(c => c.email === mail.from.email || c.workEmail === mail.from.email)
          const emailFrom = mail.from.email
          let c = null
          if (this.contacts?.find(c => c.email === emailFrom || c.workEmail === emailFrom)) {
            c = this.contacts?.find(c => c.email === emailFrom || c.workEmail === emailFrom)
          } else if (this.contacts?.find(c => 'contactPersons' in c && c.contactPersons.some(cp => cp.email === emailFrom || cp.workEmail === emailFrom))) {
            const comp = this.contacts?.find(c => 'contactPersons' in c && c.contactPersons.some(cp => cp.email === emailFrom || cp.workEmail === emailFrom))
            c = comp.contactPersons.find(cp => cp.email === emailFrom || cp.workEmail === emailFrom)
          }
          return [{
            email: mail.from.email,
            id: c?.id,
            name: c?.name || mail.from.name
          }]
        }
      } else {
        // We want to reply everyone
        // Our mailbox is the sender of the mail, so we have to use first TO or CC here
        if (direction === 'from') {
          return mail.to
            .concat(mail.cc)
            .map(p => {
              const email = p.email
              let c = null
              if (this.contacts?.find(c => c.email === email || c.workEmail === email)) {
                c = this.contacts?.find(c => c.email === email || c.workEmail === email)
              } else if (this.contacts?.find(c => 'contactPersons' in c && c.contactPersons.some(cp => cp.email === email || cp.workEmail === email))) {
                const comp = this.contacts?.find(c => 'contactPersons' in c && c.contactPersons.some(cp => cp.email === email || cp.workEmail === email))
                c = comp.contactPersons.find(cp => cp.email === email || cp.workEmail === email)
              }
              return {
                email: p.email,
                id: c?.id,
                name: c?.name || p.name
              }
            })
        } else {
          // Our mailbox is the recipient of the mail, so we have to reply to the sender of the mail
          return [mail.from]
            .concat(mail.to)
            .map(p => {
              const email = p.email
              let c = null
              if (this.contacts?.find(c => c.email === email || c.workEmail === email)) {
                c = this.contacts?.find(c => c.email === email || c.workEmail === email)
              } else if (this.contacts?.find(c => 'contactPersons' in c && c.contactPersons.some(cp => cp.email === email || cp.workEmail === email))) {
                const comp = this.contacts?.find(c => 'contactPersons' in c && c.contactPersons.some(cp => cp.email === email || cp.workEmail === email))
                c = comp.contactPersons.find(cp => cp.email === email || cp.workEmail === email)
              }
              return {
                email: p.email,
                id: c?.id,
                name: c?.name || p.name
              }
            })
        }
      }

      return []
    },
    getCcContactReferences(mail, replyAll = false) {
      // We only want to reply one, so we don't have any CC
      if (!replyAll) {
        return []
      } else {
        // We want to reply everyone
        return mail.cc
          .map(p => {
            let c = null
            if (this.contacts?.find(c => c.email === p.email || c.workEmail === p.email)) {
              c = this.contacts?.find(c => c.email === p.email || c.workEmail === p.email)
            } else if (this.contacts?.find(c => 'contactPersons' in c && c.contactPersons.some(cp => cp.email === p.email || cp.workEmail === p.email))) {
              const comp = this.contacts?.find(c => 'contactPersons' in c && c.contactPersons.some(cp => cp.email === p.email || cp.workEmail === p.email))
              c = comp.contactPersons.find(cp => cp.email === p.email || cp.workEmail === p.email)
            }
            return {
            email: p.email,
            id: c?.id || null,
            name: c?.name || p.name
            }
          })
      }
    },
    prepareReplyMail(email, sendMailContacts = []) {
      if (sendMailContacts.length > 0) {
        this.setSendMailContacts(sendMailContacts)
      } else {
        const ignoreMyMail = this.$store.getters['user/getEmail']
        const to = this.getToContactReferences(email, false).filter(e => e.email !== ignoreMyMail)
        this.setSendMailContacts(to)
      }

      this.setSendMailPreselectedContact(null)
      this.setSendMailReferenceIds(email.referenceIds)
      this.setSendMailEmailToReply(email)
      this.$nextTick(function () {
        this.callShowSendMailModal()
      })
    },
    prepareReplyAll(email) {
      const ignoreMyMail = this.$store.getters['user/getEmail']
      const to = this.getToContactReferences(email, true).filter(e => e.email !== ignoreMyMail)
      const cc = this.getCcContactReferences(email, true).filter(e => e.email !== ignoreMyMail)

      this.setSendMailContacts(to)
      this.setSendMailCC(cc)
      this.setSendMailEmailToReply(email)
      this.setSendMailPreselectedContact(null)
      this.setSendMailReferenceIds([])
      this.$nextTick(function () {
        this.callShowSendMailModal()
      })
    },
    prepareForwardMail(email) {
      this.setSendMailReferenceIds([])
      const forwardingMail = { ...email }
      forwardingMail.isForwarding = true
      this.setSendMailEmailToForward(forwardingMail)
      this.setSendMailPreselectedContact(null)
      this.setSendMailAttachmentMediaIds(forwardingMail.attachmentMediaIds)
      this.$nextTick(function () {
        this.callShowSendMailModal()
      })
    },
    async prepareSerialEmail(selectedEntries) {
      this.setSelectedEntries(selectedEntries)
      let contactIds = []
      const contactsWithObject = []
      const onlyObjectWithContact = []

      if (selectedEntries) {
        selectedEntries.forEach(element => {
          let objectContacts = []
          const objectId = element.obj?.id || element.objectId || element.objectID || element.id
          const tenancyOrCondominiumId = element.id || element.objectID
          if (!element.condominiumOwnership && !element.condominiumOwners && !tenancyOrCondominiumId.includes('condominium_')) {
            let currentTenancy
            if (tenancyOrCondominiumId.includes('tenancy_')) {
              currentTenancy = this.getTenancyFromState(tenancyOrCondominiumId)[0]
            } else {
              currentTenancy = this.getCurrentTenancy(objectId)
            }
            if (currentTenancy) {
              onlyObjectWithContact.push(currentTenancy.objectId || currentTenancy.id)
              objectContacts = objectContacts.concat(currentTenancy.tenant.contactIds)
              currentTenancy.tenant.contactIds.forEach(element => {
                objectContacts.push(element)
              })
              currentTenancy.tenant.guarantorContactIds.forEach(element => {
                objectContacts.push(element)
              })
              if (currentTenancy.tenant.billingContactId) {
                objectContacts.push(currentTenancy.tenant.billingContactId)
              }
              if (currentTenancy.tenant.correspondenceContactId) {
                objectContacts.push(currentTenancy.tenant.correspondenceContactId)
              }
              objectContacts = [...new Set(objectContacts)]
              contactsWithObject.push({
                contacts: contactIds,
                objectId: objectId
              })
              contactIds = contactIds.concat(objectContacts)
            } else if (element.tenancy) {
              // case of serial email from Rental Objects algolia
              const tenancy = element.tenancy
              onlyObjectWithContact.push(element.objectID)
              tenancy.tenants.forEach(element => {
                objectContacts.push(element.contactId)
              })
              if (tenancy.guarantors) {
                tenancy.guarantors.forEach(element => {
                  objectContacts.push(element.contactId)
                })
              }
              if (tenancy.billingContact) {
                objectContacts.push(tenancy.billingContact.contactId)
              }
              if (tenancy.correspondenceContact) {
                objectContacts.push(tenancy.correspondenceContact.contactId)
              }
              objectContacts = [...new Set(objectContacts)]
              contactsWithObject.push({
                contacts: objectContacts,
                objectId: objectId
              })
              contactIds = contactIds.concat(objectContacts)
            } else if (element.objectID?.startsWith('tenancy_')) {
              // case of serial email from Tenancies algolia
              onlyObjectWithContact.push(objectId)
              element.tenants.forEach(element => {
                objectContacts.push(element.contactId)
              })
              if (element.guarantors) {
                element.guarantors.forEach(element => {
                  objectContacts.push(element.contactId)
                })
              }
              if (element.billingContact) {
                objectContacts.push(element.billingContact.contactId)
              }
              if (element.correspondenceContact) {
                objectContacts.push(element.correspondenceContact.contactId)
              }
              objectContacts = [...new Set(objectContacts)]
              contactsWithObject.push({
                contacts: objectContacts,
                objectId: objectId
              })
              contactIds = contactIds.concat(objectContacts)
            }
          } else {
            let currentCondominium
            if (tenancyOrCondominiumId.includes('condominium_')) {
              currentCondominium = this.getCondominiumFromState(tenancyOrCondominiumId)[0]
            } else {
              currentCondominium = this.getCurrentCondominium(objectId)
            }
            if (currentCondominium) {
              objectContacts = objectContacts.concat(currentCondominium.owner.contactIds).concat([currentCondominium.owner.billingContactId]).concat([currentCondominium.owner.correspondenceContactId]).concat(currentCondominium.owner.roommateContactIds)
              objectContacts = [...new Set(objectContacts)]
              contactsWithObject.push({
                contacts: objectContacts,
                objectId: objectId
              })
              contactIds = contactIds.concat(objectContacts)
              onlyObjectWithContact.push(currentCondominium.objectId || currentCondominium.id)
            } else if (element.condominium) {
              // case of serial email from Condominiums algolia
              const condominium = element.condominium

              condominium.owner.forEach(element => {
                objectContacts.push(element.contactId)
              })
              if (condominium.roommates) {
                condominium.roommates.forEach(element => {
                  objectContacts.push(element.contactId)
                })
              }
              if (condominium.billingContact) {
                objectContacts.push(condominium.billingContact.contactId)
              }
              if (condominium.correspondenceContact) {
                objectContacts.push(condominium.correspondenceContact.contactId)
              }
              objectContacts = [...new Set(objectContacts)]
              contactsWithObject.push({
                contacts: objectContacts,
                objectId: objectId
              })
              contactIds = contactIds.concat(objectContacts)
            } else if (element.objectID?.startsWith('condominium_')) {
              // case of serial email from CondomOwners algolia
              element.condominiumOwners.forEach(element => {
                objectContacts.push(element.contactId)
              })
              if (element.roommates) {
                element.roommates.forEach(element => {
                  objectContacts.push(element.contactId)
                })
              }
              if (element.billingContact) {
                objectContacts.push(element.billingContact.contactId)
              }
              if (element.correspondenceContact) {
                objectContacts.push(element.correspondenceContact.contactId)
              }
              objectContacts = [...new Set(objectContacts)]
              contactsWithObject.push({
                contacts: objectContacts,
                objectId: objectId
              })
              contactIds = contactIds.concat(objectContacts)
            }
          }
        })
        contactIds = [...new Set(contactIds)]
        ContactApi.contactResolve(contactIds)
          .then(response => {
            const contacts = response.persons.concat(response.companies).filter(x => contactIds.includes(x.id))
            const contactsWithEmail = contacts.filter(x => x && x.email !== '')
            const contactsWithoutEmail = contacts.filter(x => x && x.email === '')
            this.setSendMailContacts(contactsWithEmail)
            this.setSendMailContactsWithoutEmail(contactsWithoutEmail)
            this.setSendMailContactsWithObject(contactsWithObject)
            this.setRenderWithObjectId(onlyObjectWithContact.length === 1 ? onlyObjectWithContact[0] : '')
            this.setSendMailPreselectedContact(contacts)
            this.setSendMailEmailToReply(null)
            this.setSendMailReferenceIds([])
            this.setBccReferenceIds([])
            this.setSendMailSecondaryTitle(true)
            this.setUseBcc(true)
            this.setSendMailSecondaryTitle(true)
            this.$nextTick(function () {
              this.callSendMailTypeModal()
            })
          })
          .catch(e => {
            console.log(e)
            Vue.toasted.show(this.$t('message.loadingErrors.contacts'), { type: 'error' })
            this.$router.back()
          })
      }
    },
    callSendMailTypeModal() {
      this.$bvModal.show('modal-send-mail-type')
    },
    getTenancyFromState(tenancyId) {
      return this.tenancies.filter(ten => {
        return ten.id === tenancyId
      })
    },
    getCondominiumFromState(tenancyId) {
      return this.condominiumOwnership.filter(ten => {
        return ten.id === tenancyId
      })
    },
    getMailConnectedWithContacts(mailObject) {
      const recipients = {
        from: {},
        to: [],
        cc: [],
        bcc: []
      }
      const referenceIds = mailObject.referenceIds?.filter((v, i, a) => a.indexOf(v) === i)
      const listMails = mailObject.to.map(e => e.email).concat(mailObject.cc.map(e => e.email)).concat(mailObject.bcc.map(e => e.email)).concat(mailObject.from?.email)

      for (const cc of mailObject.cc) {
        recipients.cc.push(this.getContactByMailAddressAndOrReferenceId(cc, referenceIds, listMails))
      }

      for (const bcc of mailObject.bcc) {
        recipients.bcc.push(this.getContactByMailAddressAndOrReferenceId(bcc, referenceIds, listMails))
      }

      if (mailObject.from?.email) {
        recipients.from = this.getContactByMailAddressAndOrReferenceId(mailObject.from, referenceIds, listMails, mailObject.inbound)
      }
      for (const [index, to] of mailObject.to.entries()) {
        if (index === 0 && (this.getDirection(mailObject) !== 'to' && this.getDirection(mailObject) !== 'cc' && this.getDirection(mailObject) !== 'bcc') && this.getManuallyAssignedContact(referenceIds)) {
          recipients.to.push(this.getManuallyAssignedContact(referenceIds))
        } else {
          recipients.to.push(this.getContactByMailAddressAndOrReferenceId(to, referenceIds, listMails, !mailObject.inbound))
        }
      }
      return recipients
    },
    getContactByMailAddressAndOrReferenceId(email, referenceIds, listMails, inbound = null) {
      const emailAddress = email?.email.toLowerCase().trim()
      if (referenceIds?.filter(e => e.startsWith('contact_'))?.length > 1) {
        // Try to match by referenceId and email address
        if (this.contacts?.length) {
          for (const contact of this.contacts) {
            if ((contact.email?.toLowerCase().trim() === emailAddress || contact.workEmail?.email?.toLowerCase().trim() === emailAddress) && referenceIds.includes(contact.id)) {
              return {
                contactId: contact.id,
                name: contact.name,
                email: emailAddress
              }
            }
            if (contact.contactPersons?.length) {
              for (const contactPerson of contact.contactPersons) {
                if (contactPerson.email?.toLowerCase().trim() === emailAddress && referenceIds.includes(contactPerson.id)) {
                  return {
                    contactId: contact.id,
                    name: contactPerson.name,
                    email: contactPerson.email
                  }
                }
              }
            }
          }
        }
      } else if (referenceIds?.filter(e => e.startsWith('contact_'))?.length === 1) {
        const newListMail = inbound !== null ? listMails.filter(e => e !== emailAddress) : listMails
        const contact = this.contacts.find(e => e.id === referenceIds.find(e => e.startsWith('contact_')) && !newListMail.includes(e.email.toLowerCase().trim()))
        if (contact) {
          return {
            contactId: contact.id,
            name: contact.name,
            email: contact.email
          }
        }
      }
      // If above matching failed, then instead returning data from contact, return name from mail object
      return {
        contactId: null,
        name: email.name,
        email: email.email
      }
    },
    getManuallyAssignedContact(referenceIds) {
      // If manually assigned mail then we only have 1 contact reference (to-do: back-end should not overwrite existing contacts references by linking)
      // To-do: back-end should allow linking contact with specific mail address of recipient, not with whole mail. At the moment when many recipients, then "from" or first entry in "to" are linked.
      if (referenceIds?.filter(e => e.startsWith('contact_'))?.length > 1) {
        const contactId = referenceIds.find(e => e.startsWith('contact_'))
        const contactMatched = this.contacts?.find(e => e.id === contactId)
        if (contactMatched && !this.mailboxesForCurrentUser.map(mailbox => mailbox.outboundEmail.toLowerCase().trim()).includes(contactMatched.email.toLowerCase().trim())) {
          return {
            contactId: contactMatched.id,
            name: contactMatched.name,
            email: contactMatched.email
          }
        } else if (referenceIds?.filter(e => e.startsWith('contact_'))?.length === 1) {
          const contact = this.contacts.find(e => e.id === referenceIds.find(e => e.startsWith('contact_')))
          if (contact) {
            return {
              contactId: contact.id,
              name: contact.name,
              email: contact.email
            }
          }
        } else {
          return false
        }
      }
    },
    convertToPlainWithBreakingLines(html) {
      let htmlCleaned = html
      // remove code brakes and tabs
      htmlCleaned = htmlCleaned.replace(/\n/g, '')
      htmlCleaned = htmlCleaned.replace(/\t/g, '')

      // keep html brakes and tabs
      htmlCleaned = htmlCleaned.replace(/<\/td>/g, '\t')
      htmlCleaned = htmlCleaned.replace(/<\/table>/g, '\n')
      htmlCleaned = htmlCleaned.replace(/<\/tr>/g, '\n')
      htmlCleaned = htmlCleaned.replace(/<\/p>/g, '\n\n')
      htmlCleaned = htmlCleaned.replace(/<\/div>/g, '\n')
      htmlCleaned = htmlCleaned.replace(/<\/h>/g, '\n')
      htmlCleaned = htmlCleaned.replace(/<br>/g, '\n')
      htmlCleaned = htmlCleaned.replace(/<br( )*\/>/g, '\n')
      const tempDivElement = document.createElement('div')
      tempDivElement.innerHTML = htmlCleaned
      return tempDivElement.textContent || tempDivElement.innerText || ''
    },
    routeToDetail(ticket) {
      if (this.isOwnerModule) {
        this.$router.push({
          name: 'OwnerTicketDetailsView',
          params: { id: ticket.key }
        }, () => {
        })
      } else if (this.isAdminModule) {
        this.$router.push({
          name: 'AdminTicketDetailsView',
          params: { id: ticket.key }
        }, () => {
        })
      } else if (this.isAssetModule) {
        this.$router.push({
          name: 'AssetTicketDetailsView',
          params: { id: ticket.key }
        }, () => {
        })
      } else if (this.isAccountingModule) {
        this.$router.push({
          name: 'AccountingTicketDetailsView',
          params: { id: ticket.key }
        }, () => {
        })
      } else {
        this.$router.push({
          name: 'MarketingTicketDetailsView',
          params: { id: ticket.key }
        }, () => {
        })
      }
    },
    openMiddleDetail(ticket) {
      let routeData = ''
      if (this.isOwnerModule) {
        routeData = this.$router.resolve({
          name: 'OwnerTicketDetailsView',
          params: { id: ticket.key }
        }, () => {
        })
      } else if (this.isAdminModule) {
        routeData = this.$router.resolve({
          name: 'AdminTicketDetailsView',
          params: { id: ticket.key }
        }, () => {
        })
      } else if (this.isAssetModule) {
        routeData = this.$router.resolve({
          name: 'AssetTicketDetailsView',
          params: { id: ticket.key }
        }, () => {
        })
      } else if (this.isAccountingModule) {
        routeData = this.$router.resolve({
          name: 'AccountingTicketDetailsView',
          params: { id: ticket.key }
        }, () => {
        })
      } else {
        routeData = this.$router.resolve({
          name: 'MarketingTicketDetailsView',
          params: { id: ticket.key }
        }, () => {
        })
      }
      window.open(routeData.href, '_blank')
    }
  }
}

export { mail }
